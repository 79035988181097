<template>
  <div class="main">
    <Navbar />
    <section class="main-hero">
      <!--      <img class="brand-wave" src="@/assets/wave-path.svg">-->
      <p class="main-title">The group is full at the moment</p>
      <p class="main-subtitle">Sign up for the waitlist to reserve your spot.</p>

      <div class="form">
        <div class="username field">
          <label for="username">Chat app</label>
          <input v-model.trim="chatApp" type="text" name="chatapp" id="chatapp" placeholder="Discord, Telegram, etc.">
        </div>

        <div class="password field">
          <label for="username">Username</label>
          <input v-model="username" type="text" name="username" id="username" placeholder="user#1234">
        </div>
        <div class="buttons">
          <button @click="signup()" :disabled="disableSignup" class="submit">
            <template v-if="loading">Loading...</template>
            <template v-else>Submit</template>
          </button>
        </div>
      </div>
      <!--      <p class="main-subtitle">analysis - education - discussion</p>-->
    </section>
    <div class="modal" :class="{ 'is-active': submitted }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Success</p>
        </header>
        <section class="modal-card-body">
          <p>You are successfully signed up for the waitlist.</p>
        </section>
        <footer class="modal-card-foot">
          <router-link to="/" class="button is-dark">Cool</router-link>
        </footer>
      </div>
    </div>
    <DisclaimerModal :active="disclaimer" @close="disclaimer = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import DisclaimerModal from "@/components/DisclaimerModal";
import PasswordResetModal from "@/components/PasswordResetModal";
import WaitlistService from "@/services/WaitlistService";

export default {
  name: "Waitlist",
  components: {
    Navbar,
    DisclaimerModal,
  },
  data() {
    return {
      chatApp: null,
      username: null,
      loading: false,
      submitted: false,
      disclaimer: false
    }
  },
  computed: {
    disableSignup() {
      return this.chatApp === '' || this.username === '' || this.loading;
    }
  },
  methods: {
    async signup() {
      const data = {
        chatApp: this.chatApp,
        username: this.username,
      };
      try {
        this.loading = true;
        await WaitlistService.signup(data);
        this.submitted = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 76px);
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  font-family: 'Work Sans', sans-serif;
  background: linear-gradient(180deg, var(--background-color-gradient-secondary) 0%, var(--background-color-gradient-tertiary) 100%);
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url('~@/assets/ocean-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.main-hero {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .brand-wave {
    width: 90px;
  }
  .main-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  .main-subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 176.8%;
    /* or 28px */
    text-align: center;
    color: #FFFFFF;
  }
}
.main-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  z-index: 2;
  a {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #FFFFFF;
  }
}

.form {
  margin-top: 45px;
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: flex-end;
  label {
    color: white;
    font-size: 16px;
    //text-align: right;
  }
  .field {
    display: flex;
    gap: 22px;
  }
  input {
    max-width: 290px;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px 12px;
    &::placeholder {
      font-family: 'Work Sans';
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      color: #E5E5E5;
    }
  }
  .buttons {
    display: flex;
    gap: 44px;
    a {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      text-decoration-line: underline;
      color: #FFFFFF;
    }
    button.submit {
      &:disabled {
        cursor: not-allowed;
      }
      cursor: pointer;
      border: none;
      padding: 5px 13px;
      background: var(--submit-button);
      border-radius: 5px;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}
</style>
